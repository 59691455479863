<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            md="10"
            sm="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza cor e tamanho:
          </v-col>
          <v-col
            md="2"
            sm="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            md="10"
            sm="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza classificação fiscal do produto por loja:
          </v-col>
          <v-col
            md="2"
            sm="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
        <v-divider />
        <v-row 
          no-gutters
        >
          <v-col
            md="10"
            sm="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Exibe preço de custo na etiqueta:
          </v-col>
          <v-col
            sm="2"
            md="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-5"
          >
            <v-switch inset />
          </v-col>
        </v-row>
        <v-row
          no-gutters
        >
          <v-col 
            md="10"
            sm="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Gera código de barras:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="2" 
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-tooltip                
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  class="d-flex justify-center pt-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Este campo serve para indicar se no cadastro de um novo produto o sistema irá gerar um código de barras para o produto ou não.
              </span>
            </v-tooltip>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-switch
              inset
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{attrs, on}">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  
                  class="pl-7 pb-2"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Este campo serve para indicar se no cadastro de um novo produto o sistema irá gerar um código de barras para o produto ou não.
              </span>
            </v-tooltip>
          </v-col>          
          <v-row
            no-gutters
          >
            <v-col
              md="10"
              sm="10"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="d-flex align-center"
            >
              Utiliza promoção no produto:
            </v-col>
            <v-col
              v-show="$vssWidth < 600"
              sm="2" 
              md="1"
              :cols="$vssWidth < 600 ? 2 : 8"
              class="pt-2"
            >
              <v-tooltip                
                top
              >
                <template #activator="{attrs, on}">
                  <v-icon
                    class="d-flex justify-center pt-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <span>
                  Caso sim, exibe o campo "Vlr. anterior" na tela de "Manutenção de produtos".
                </span>
              </v-tooltip>
            </v-col>
            <v-col 
              sm="1"
              md="1"
              :cols="$vssWidth < 600 ? 2 : 8"
            >
              <v-switch
                inset
              />
            </v-col>
            <v-col             
              sm="1"
              md="1"
              :cols="$vssWidth < 600 ? 1 : 8"
              class="d-flex align-center"
            >
              <v-tooltip
                top
              >
                <template #activator="{attrs, on}">
                  <v-icon
                    v-show="$vssWidth >= 600"                  
                    v-bind="attrs"
                  
                    class="pl-7 pb-2"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <span>
                  Caso sim, exibe o campo "Vlr. anterior" na tela de "Manutenção de produtos".
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>